import * as Firebase from 'firebase/app'
import 'firebase/firestore'

function initFirebase() {
    Firebase.initializeApp({
        apiKey: "AIzaSyCgof1JxhfVx5UIY2YA6R4AjJ26lI3icS8",
        authDomain: "bort-be88f.firebaseapp.com",
        databaseURL: "https://bort-be88f.firebaseio.com",
        projectId: "bort-be88f",
        storageBucket: "bort-be88f.appspot.com",
        messagingSenderId: "963461373493",
        appId: "1:963461373493:web:a34f8152faed3c58025b7c",
        measurementId: "G-CWWH7EYJCC"
    })
    return new Promise((resolve, reject) => {
        Firebase.firestore().enablePersistence()
            .then(resolve)
            .catch(err => {
                if (err.code === 'failed-precondition') {
                    reject(err)
                    // Multiple tabs open, persistence can only be
                    // enabled in one tab at a a time.
                } else if (err.code === 'unimplemented') {
                    reject(err)
                    // The current browser does not support all of
                    // the features required to enable persistence
                }
            })
    })
}

export {
    Firebase,
    initFirebase
}