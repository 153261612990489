<template>
  <v-app-bar app clipped-left mini>
    <v-app-bar-nav-icon
      v-if="usuario ? usuario.tipo != 'anonimo' : false"
      @click.stop="drawer = !drawer"
    ></v-app-bar-nav-icon>
    <v-toolbar-title>{{titulo}}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-col cols="auto" class="text-center text-uppercase display-1">bort.cl</v-col>
    <v-spacer></v-spacer>
  </v-app-bar>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  props: {
    titulo: String,
    usuario: Object
  },
  data: () => ({}),
  computed: {
    ...mapState({
      getDrawer: state => state.drawer
    }),
    drawer: {
      get() {
        return this.getDrawer;
      },
      set(val) {
        this.setDrawer(val);
      }
    }
  },
  methods: {
    ...mapMutations({
      setDrawer: "setDrawer"
    })
  }
};
</script>