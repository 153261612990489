<template>
  <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" fixed app>
    <v-img :src="''"></v-img>
    <v-list dense>
      <template v-for="(item,index) in items">
        <template v-if="item.subheader">
          <v-divider :key="item.text+'@'+'d'"></v-divider>
          <v-subheader :key="item.text+'@'+'s'">{{item.text}}</v-subheader>
        </template>
        <v-card v-else tile flat color="primary" dark class="mb-1" :key="index">
          <v-list-item :to="item.to">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </template>
      <v-divider></v-divider>
      <v-subheader>Opciones</v-subheader>
      <v-card tile flat color="primary" dark class="mb-1">
        <v-list-item @click="$emit('logout')">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-title>
        </v-list-item>
      </v-card>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  data: () => ({}),
  props: {
    items: Array
  },
  computed: {
    ...mapState({
      getDrawer: "drawer"
    }),
    drawer: {
      get() {
        return this.getDrawer;
      },
      set(val) {
        this.setDrawer(val);
      }
    }
  },
  methods: {
    ...mapMutations({
      setDrawer: "setDrawer"
    }),
    goTo(to) {
      this.$router.push(to);
    }
  }
};
</script>
