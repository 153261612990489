// firebase
import * as Firebase from "firebase/app";
import "firebase/storage";

const state = {
    all: {}
}

const getters = {}

const actions = {
    get(ctx, data) {
        var storage = Firebase.storage();
        var storageRef = storage.ref();
        var archivoRef = storageRef.child(data.ref);
        archivoRef.getDownloadURL().then(url => {
            var doc = {
                id: data.id,
                ref: data.ref,
                url: url
            }
            ctx.commit('set', doc)
            console.log("archivo/get", doc)
        }).catch(error => {
            console.log(error)
        })
    },
    set(ctx, data) {
        var storage = Firebase.storage();
        var storageRef = storage.ref();
        var archivoRef = storageRef.child(data.ref);
        archivoRef.put(data.archivo).then(() => {
            archivoRef.getDownloadURL().then(url => {
                var doc = {
                    id: data.id,
                    ref: data.ref,
                    url: url
                }
                ctx.commit('set', doc)
            })
        }).catch(error => {
            console.log(error)
        })
    }
}

const mutations = {
    set(state, data) {
        state.all = Object.assign({}, state.all, {
            [data.id]: data
        })
        console.log("archivo/set", data)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}