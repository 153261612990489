// ~store/index.js
import Vue from 'vue'
import Vuex from 'vuex'
import VuexEasyFirestore from 'vuex-easy-firestore'
Vue.use(Vuex)

// import from step 1
import {
  Firebase,
  initFirebase
} from '../config/firebase.js'

// import from step 3 (below)
import usuarios from './modules/usuarios.js'
import pdfs from './modules/pdfs.js'

// do the magic 🧙🏻‍♂️
const easyFirestore = VuexEasyFirestore(
  [usuarios, pdfs], {
    logging: true,
    FirebaseDependency: Firebase
  }
)

// modules vuex
import archivos from "./modules/archivos";

const appName = "bort-cl"

const storeData = {
  plugins: [easyFirestore],
  state: {
    user: JSON.parse(localStorage.getItem(appName + "@user")),
    usuario: null,
    drawer: false
  },
  getters: {},
  mutations: {
    setUser(state, data) {
      data ? localStorage.setItem(appName + "@user", JSON.stringify(data)) : localStorage.removeItem(appName + "@user")
      state.user = data ? Object.assign({}, state.user, JSON.parse(localStorage.getItem(appName + "@user"))) : data;
    },
    setUsuario(state, data) {
      state.usuario = data ? Object.assign({}, state.usuario, data) : data
    },
    setDrawer(state, data) {
      state.drawer = data
    }
  },
  actions: {},
  modules: {
    archivos
  }
}

// initialise Vuex
const store = new Vuex.Store(storeData)

// initFirebase
initFirebase()
  .then(() => {
    Firebase.auth().onAuthStateChanged(user => {
      if (user && !user.isAnonymous) {
        store.dispatch('usuarios/openDBChannel', {
          where: [
            ['email', '==', user.email]
          ]
        }).then(() => {
          let usuario = Object.assign({}, store.state.usuarios.all[user.email]);
          console.log("login admin")
          if (usuario && usuario.tipo == "admin") {
            store.dispatch('usuarios/openDBChannel', {
              where: []
            })
          }
          store.dispatch('pdfs/openDBChannel')
          store.commit("setUser", user)
          store.commit("setUsuario", usuario)
        })
      } else if (user && user.isAnonymous) {
        store.dispatch('usuarios/openDBChannel', {
          where: [
            ['email', '==', "anonimo"]
          ]
        }).then(() => {
          let usuario = Object.assign({}, store.state.usuarios.all["11111111-1"]);
          store.dispatch('pdfs/openDBChannel')
          store.commit("setUser", user)
          store.commit("setUsuario", usuario)
        })
      } else {
        store.dispatch('pdfs/closeDBChannel')
        store.dispatch('usuarios/closeDBChannel', {
          clearModule: true
        }).then(() => {
          store.commit("setUser", null)
          store.commit("setUsuario", null)
        }).catch(error => {
          console.log(error)
        })
      }
    })
  })
  .catch(error => {
    console.log(error)
  })

export default store