<template>
  <v-app>
    <v-content>
      <v-container fill-height>
        <v-layout fill-height justify-center align-center>
          <v-flex xs10 md4>
            <v-card>
              <v-flex px-4>
                <v-layout fill-height align-center>
                  <v-flex>
                    <v-layout column>
                      <v-img :src="''"></v-img>
                      <v-flex text-center primary--text font-weight-light>Iniciar Sesion</v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex px-4>
                <formulario-login
                  btnText="login"
                  btnRegistro
                  v-on:click-btn="login"
                  v-on:facebook="facebook"
                  v-on:google="google"
                  v-on:registro="$router.push({name:'registro'})"
                ></formulario-login>
              </v-flex>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
//vuex
import { mapState, mapMutations, mapActions } from "vuex";
//firebase
import * as Firebase from "firebase/app";
import "firebase/auth";
//components
import FormularioLogin from "./../components/login/Formulario";

export default {
  name: "login",
  created() {
    if (this.usuario ? this.usuario.tipo != "anonimo" : false) {
      this.$router.push({ name: "home.inicio" });
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      usuario: state => state.usuario,
      usuarios: state => state.usuarios.all
    })
  },
  watch: {
    usuario(val) {
      if (val && val.tipo != "anonimo") {
        this.$router.push({ name: "home.inicio" });
      }
    }
  },
  methods: {
    ...mapMutations({
      setUser: "setUser"
    }),
    ...mapActions({
      openUsuarios: "usuarios/openDBChannel",
      closeUsuarios: "usuarios/closeDBChannel"
    }),
    login(data) {
      var router = this.$router;
      var toastr = this.$toastr;
      var email = data.email;
      var password = data.password;

      if (email != "" && email != null) {
        this.cargando = true;
        this.openUsuarios({ where: [["email", "==", email]] }).then(() => {
          let usuario = Object.values(this.usuarios).find(item => {
            return item.email == email;
          });
          if (usuario && usuario.tipo) {
            this.closeUsuarios().then(() => {
              Firebase.auth()
                .signInWithEmailAndPassword(email, password)
                .then(user => {
                  this.setUser(user);
                  router.push({ name: "home.cargando" });
                })
                .catch(function(error) {
                  // Handle Errors here.

                  var errorCode = error.code;
                  console.log(errorCode);

                  if (errorCode == "auth/user-not-found") {
                    toastr.error("Usuario no Existe", "Error");
                  }
                  if (errorCode == "auth/wrong-password") {
                    toastr.error("Password Incorrecto", "Error");
                  }
                  var errorMessage = error.message;
                  console.log(errorMessage);
                  // ...
                });
            });
          } else {
            this.closeUsuarios();
            this.$toastr.error("email no existe", "error");
          }
        });
      } else {
        this.$toastr.error("email vacio", "error");
      }
    },
    facebook() {
      var router = this.$router;
      var provider = new Firebase.auth.FacebookAuthProvider();
      Firebase.auth()
        .signInWithPopup(provider)
        .then(function(result) {
          // This gives you a Facebook Access Token. You can use it to access the Facebook API.
          var token = result.credential.accessToken;
          console.log(token);
          // The signed-in user info.
          var user = result.user;
          console.log(user);
          // ...
          router.push({ name: "home" });
        })
        .catch(function(error) {
          // Handle Errors here.
          var errorCode = error.code;
          console.log(errorCode);
          var errorMessage = error.message;
          console.log(errorMessage);
          // The email of the user's account used.
          var email = error.email;
          console.log(email);
          // The firebase.auth.AuthCredential type that was used.
          var credential = error.credential;
          console.log(credential);
          // ...
        });
    },
    google() {
      var router = this.$router;
      var provider = new Firebase.auth.GoogleAuthProvider();
      Firebase.auth()
        .signInWithPopup(provider)
        .then(function(result) {
          // This gives you a Google Access Token. You can use it to access the Google API.
          var token = result.credential.accessToken;
          console.log(token);
          // The signed-in user info.
          var user = result.user;
          console.log(user);
          // ...
          router.push({ name: "home" });
          // ...
        })
        .catch(function(error) {
          // Handle Errors here.
          var errorCode = error.code;
          console.log(errorCode);
          var errorMessage = error.message;
          console.log(errorMessage);
          // The email of the user's account used.
          var email = error.email;
          console.log(email);
          // The firebase.auth.AuthCredential type that was used.
          var credential = error.credential;
          console.log(credential);
          // ...
        });
    }
  },
  components: {
    FormularioLogin
  }
};
</script>

<style>
</style>