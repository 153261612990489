<template>
  <v-row no-gutters>
    <v-col cols="12" v-if="pdfActual">
      <pdf
        v-for="i in numPages"
        :key="i"
        :src="src"
        :page="i"
        style="display: inline-block; width: 100%"
      ></pdf>
    </v-col>
  </v-row>
</template>

<script>
import pdf from "vue-pdf";

export default {
  props: {
    pdfs: Object,
    archivos: Object,
  },
  created() {
    //
  },
  data() {
    return {
      indice: 0,
      numPages: 1,
      src: null,
      url: "",
    };
  },
  computed: {
    pdfActual() {
      let item = this.itemsPDFs[this.indice];
      return item ? this.archivos[item.id] : null;
    },
    itemsPDFs() {
      return this.pdfs
        ? Object.values(this.pdfs).sort((a, b) => {
            return new Date(b.fecha) - new Date(a.fecha);
          })
        : [];
    },
  },
  watch: {
    pdfActual(val) {
      if (!val && this.itemsPDFs[this.indice]) {
        this.src = null;
        this.$emit("get-archivo", this.itemsPDFs[this.indice]);
      }
      if (val && !this.src) {
        this.src = pdf.createLoadingTask(val.url);
        this.src.promise.then(async (res) => {
          await this.$nextTick();
          this.numPages = res.numPages;
        });
      }
    },
  },
  components: {
    pdf,
  },
};
</script>

<style></style>
