<template>
  <v-card flat color="transparent">
    <v-row>
      <v-col cols="12">
        <v-text-field
          :readonly="!editable"
          label="Usuario (email)"
          outlined
          hide-details
          prepend-inner-icon="mdi-account"
          v-model="mail"
          :error-messages="mailError"
          @input="$v.mail.$touch()"
          @blur="$v.mail.$touch()"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          label="Contraseña"
          prepend-inner-icon="mdi-lock"
          v-model="password"
          outlined
          hide-details
          type="password"
          counter
          :error-messages="passwordError"
          @input="$v.password.$touch()"
          @blur="$v.password.$touch()"
        ></v-text-field>
      </v-col>
      <v-col cols="12" class="pb-2 pt-5">
        <v-row justify="center">
          <v-col cols="12">
            <v-btn style="width: 100% " class="white--text" color="#02538b" @click="registro">
              <v-icon left>mdi-login</v-icon>
              {{ btnText }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col style="text-align: center" cols="12">
            <a v-if="btnRegistro" color="accent" @click="$emit('registro')">Activar Cuenta</a>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
//vuelidate
import { email, required, minLength } from "vuelidate/lib/validators";

export default {
  props: {
    btnText: String,
    email: String,
    btnRegistro: Boolean
  },
  created() {
    this.email
      ? (this.mail = this.email ? this.email : "")
      : (this.editable = true);
  },
  data() {
    return {
      mail: "",
      password: "",
      editable: false
    };
  },
  validations: {
    mail: {
      email,
      required
    },
    password: {
      minLength: minLength(6),
      required
    }
  },
  computed: {
    mailError() {
      var errors = [];
      if (!this.$v.mail.$dirty) return errors;
      if (!this.$v.mail.required) errors.push("Requerido");
      if (!this.$v.mail.email) errors.push("Tiene que ser un Email");
      return errors;
    },
    passwordError() {
      var errors = [];
      if (!this.$v.password.$dirty) return errors;
      if (!this.$v.password.required) errors.push("Requerido");
      if (!this.$v.password.minLength) errors.push("Minimo 6 caracteres");
      return errors;
    }
  },
  methods: {
    registro() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit(
          "click-btn",
          Object.assign(
            {},
            {
              email: this.mail,
              password: this.password
            }
          )
        );
      }
    }
  }
};
</script>
