<template>
  <v-row no-gutters>
    <v-col v-for="pdf in pdfs" :key="pdf.id">pdf</v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    pdfs: Object
  }
};
</script>

<style>
</style>