<template>
  <v-app>
    <toolbar
      v-if="false"
      :titulo="usuario && usuario.nombre ? usuario.nombre : ''"
      :usuario="usuario"
    ></toolbar>
    <template v-if="usuario ? usuario.id != 'anonimo' : false">
      <navigation-drawer :items="itemsRutas" :usuario="usuario" v-on:logout="logout"></navigation-drawer>
    </template>
    <router-view
      :user="user"
      :usuario="usuario"
      :pdfs="pdfs"
      :usuarios="usuarios"
      :archivos="archivos"
      v-on:set-usuario="setUsuario"
      v-on:set-archivo="setArchivo"
      v-on:set-pdf="setPDF"
      v-on:delete-usuario="deleteUsuario"
      v-on:get-archivo="getArchivo"
    ></router-view>
  </v-app>
</template>

<script>
//vuex
import { mapState, mapActions } from "vuex";
//firebase
import * as Firebase from "firebase/app";
import "firebase/auth";
//componentes
import Toolbar from "./../components/Toolbar";
import NavigationDrawer from "./../components/NavigationDrawer";

export default {
  name: "home",
  data() {
    return {
      rutas: {
        admin: [
          {
            subheader: true,
            text: "Administrador"
          },
          {
            icon: "mdi-home",
            text: "Inicio",
            to: { name: "home.admin.inicio" }
          },
          {
            icon: "mdi-account-circle",
            text: "Usuarios",
            to: { name: "home.admin.usuarios" }
          },
          {
            icon: "mdi-account",
            text: "PDFs",
            to: { name: "home.admin.pdfs" }
          }
        ],
        aditor: [
          {
            subheader: true,
            text: "Editor"
          },
          {
            icon: "mdi-home",
            text: "Inicio",
            to: { name: "home.editor.inicio" }
          },
          {
            icon: "mdi-account",
            text: "PDFs",
            to: { name: "home.editor.pdfs" }
          }
        ]
      }
    };
  },
  computed: {
    ...mapState({
      user: state => state.user,
      usuario: state => state.usuario,
      usuarios: state => state.usuarios.all,
      pdfs: state => state.pdfs.all,
      archivos: state => state.archivos.all
    }),
    itemsRutas() {
      var rutas = [];
      this.usuario && this.usuario.tipo
        ? (rutas = this.rutas[this.usuario.tipo]
            ? this.rutas[this.usuario.tipo]
            : console.log("rutas no definidas"))
        : console.log("usuario indefinido");
      return rutas;
    }
  },
  methods: {
    ...mapActions({
      setUsuario: "usuarios/set",
      setArchivo: "archivos/set",
      setPDF: "pdfs/set",
      deleteUsuario: "usuarios/delete",
      getArchivo: "archivos/get"
    }),
    login() {
      this.$router.push({ name: "login" });
    },
    logout() {
      // var router = this.$router;
      Firebase.auth()
        .signOut()
        .then(function() {
          // Sign-out successful.
        })
        .catch(function(error) {
          // An error happened.
          console.log(error);
        });
    },
    fechaLocal() {
      let fecha = new Date();
      return (
        fecha.getFullYear().toString() +
        "-" +
        (fecha.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        fecha
          .getDate()
          .toString()
          .padStart(2, "0")
      );
    },
    horaLocal() {
      let fecha = new Date();
      return (
        fecha
          .getHours()
          .toString()
          .padStart(2, "0") +
        ":" +
        fecha
          .getMinutes()
          .toString()
          .padStart(2, "0") +
        ":" +
        fecha
          .getSeconds()
          .toString()
          .padStart(2, "0")
      );
    }
  },
  components: {
    Toolbar,
    NavigationDrawer
  }
};
</script>
