<template>
  <v-data-table :headers="headers" :items="itemsUsuarios">
    <template v-slot:item.action="{ item }">
      <template v-if="usuario && usuario.id != item.id && item.tipo != 'anonimo'">
        <v-row no-gutters>
          <v-col cols="auto" class="px-1">
            <v-btn x-small icon @click="$emit('click-edit', item)">
              <v-icon small>mdi-account-edit</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="auto" class="px-1">
            <v-btn x-small icon @click="$emit('click-remove', item)">
              <v-icon small>mdi-account-remove</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    usuario: Object,
    usuarios: Object
  },
  data() {
    return {
      headers: [
        {
          text: "Email",
          align: "left",
          value: "id"
        },
        { text: "Tipo", value: "tipo" },
        { text: "Opciones", value: "action" }
      ]
    };
  },
  computed: {
    itemsUsuarios() {
      return this.usuarios ? Object.values(this.usuarios) : [];
    }
  },
  methods: {},
  components: {}
};
</script>
      