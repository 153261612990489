<template>
  <v-content>
    <router-view
      :usuario="usuario"
      :usuarios="usuarios"
      v-on:set-usuario="setUsuario"
      v-on:delete-usuario="deleteUsuario"
    ></router-view>
  </v-content>
</template>

<script>
export default {
  props: {
    usuario: Object,
    usuarios: Object
  },
  created() {
    if (this.usuario) {
      this.usuario.tipo == "admin"
        ? console.log("acceso admin")
        : this.$router.push({ name: "home.inicio" });
    }
  },
  watch: {
    usuario(val) {
      if (val) {
        val.tipo == "admin"
          ? console.log("acceso admin")
          : this.$router.push({ name: "home.inicio" });
      }
    }
  },
  methods: {
    setUsuario(dataUsuario) {
      this.$emit("set-usuario", dataUsuario);
    },
    deleteUsuario(id) {
      console.log(id);
      this.$emit("delete-usuario", id);
    }
  }
};
</script>

<style></style>
