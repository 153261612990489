<template>
  <v-container fill-height>
    <v-row justify="center" align="center">
      <v-col cols="auto">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//firebase
import * as Firebase from "firebase/app";
import "firebase/auth";

export default {
  props: {
    user: Object,
    usuario: Object
  },
  created() {
    if (!this.user) {
      Firebase.auth()
        .signInAnonymously()
        .catch(function(error) {
          // Handle Errors here.
          // var errorCode = error.code;
          // var errorMessage = error.message;
          // ...
          console.log("login anonimo", error);
        });
    }
  },
  watch: {
    usuario(val) {
      if (val) {
        this.$router.push({ name: "home.inicio" });
      }
    }
  }
};
</script>

<style>
</style>