<template>
  <v-row no-gutters class="ma-0">
    <v-col cols="12">
      <v-row no-gutters class="pt-3">
        <v-col cols="12" class="text-uppercase text-center">subida archivo</v-col>
      </v-row>
      <v-row dense class="mx-3 py-3">
        <v-col cols="12">
          <v-file-input
            label="Archivo"
            accept=".pdf"
            show-size
            :rules="rules"
            v-model="archivo"
            prepend-icon="mdi-pdf-box"
          ></v-file-input>
        </v-col>
        <v-col cols="12" class="pt-3">
          <v-row justify="center">
            <v-btn color="accent" :disabled="!valido" @click="guardar">guardar</v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    datos: Object
  },
  data: () => ({
    rules: [
      value =>
        !value ||
        value.size < 10000000 ||
        "El peso de la imagen debe ser menor a 10 MB!"
    ],
    archivo: null
  }),
  computed: {
    archivoValido() {
      return this.archivo && this.archivo.size < 10000000;
    },
    valido() {
      return this.archivoValido;
    }
  },
  methods: {
    guardar() {
      if (this.valido) {
        this.$emit("set-archivo", {
          ref: this.datos.ref,
          archivo: this.archivo,
          id: this.datos.id
        });
        this.$emit("cerrar");
      } else {
        console.log("archivo no valida");
      }
    }
  }
};
</script>

<style>
</style>