<template>
  <v-container>
    <v-row>
      <v-col v-for="pdf in pdfs" :key="pdf.id">pdf</v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    pdfs: Object
  }
};
</script>

<style>
</style>