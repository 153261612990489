<template>
  <router-view></router-view>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "App",
  created() {
    if (this.$route.name == null || !this.user) {
      this.$router.push({ name: "home.cargando" });
    }
  },
  data: () => ({
    //
  }),
  computed: {
    ...mapState({
      user: state => state.user
    })
  },
  watch: {
    user(val) {
      if (!val) {
        this.$router.push({ name: "home.cargando" });
      }
    }
  }
};
</script>