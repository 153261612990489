<template>
  <v-app>
    <v-row
      style="background: radial-gradient(circle, rgba(0,15,36,1) 0%, rgba(39,174,192,1) 0%, rgba(2,83,139,1) 100%);"
      v-if="!cargando"
      justify="center"
      align="center"
    >
      <v-col cols="6">
        <v-card>
          <v-card color="primary" tile dark>
            <v-col class="text-center text-uppercase title">Ingrese su Email</v-col>
          </v-card>
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field outlined dense hide-details label="Email" v-model="email"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="white--text" style="width: 100%" color="#02538b" @click="enviar">enviar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else justify="center" align="center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-row>
    <v-dialog
      style="background: radial-gradient(circle, rgba(0,15,36,1) 0%, rgba(39,174,192,1) 0%, rgba(2,83,139,1) 100%);"
      v-model="dialogoRegistro"
      max-width="600px"
    >
      <v-card v-if="dialogoRegistro">
        <v-card color="primary" dark>
          <v-col class="text-center text-uppercase title">Formulario Registro</v-col>
        </v-card>
        <v-card-text>
          <formulario-login :email="email" btnText="registro" v-on:click-btn="registro"></formulario-login>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
// vuex
import { mapState, mapActions, mapMutations } from "vuex";
//
//firebase
import * as Firebase from "firebase/app";
import "firebase/auth";
// components
import FormularioLogin from "./../components/login/Formulario";

export default {
  data() {
    return {
      email: "",
      cargando: false,
      dialogoRegistro: false
    };
  },
  computed: {
    ...mapState({
      usuarios: state => state.usuarios.all
    })
  },
  methods: {
    ...mapActions({
      openUsuarios: "usuarios/openDBChannel",
      closeUsuarios: "usuarios/closeDBChannel"
    }),
    ...mapMutations({
      setUser: "setUser"
    }),
    enviar() {
      if (this.email != "" && this.email != null) {
        this.cargando = true;
        this.openUsuarios({ where: [["email", "==", this.email]] }).then(() => {
          if (this.usuarios[this.email] && this.usuarios[this.email].tipo) {
            this.dialogoRegistro = true;
            this.closeUsuarios();
          } else {
            this.cargando = false;
            this.$toastr.error("email no existe", "error");
            this.closeUsuarios();
          }
        });
      } else {
        this.cargando = false;
        this.$toastr.error("email vacio", "error");
      }
    },
    registro(data) {
      Firebase.auth()
        .createUserWithEmailAndPassword(data.email, data.password)
        .then(user => {
          console.log(user);
          console.log(this.usuarios);
          //this.setUser(user);

          if (this.usuarios[this.email].tipo == "admin") {
            this.$router.push({ name: "home.admin.inicio" });
          } else if (this.usuarios[this.email].tipo == "editor") {
            this.$router.push({ name: "home.editor.inicio" });
          }
        })
        .catch(error => {
          console.log(error);
          this.dialogoRegistro = false;
          this.cargando = false;
          this.$toastr.error("Cuenta ya activa", "error");
        });
    }
  },
  components: {
    FormularioLogin
  }
};
</script>
