<template>
  <v-container>
    <v-btn color="warning" fab right bottom fixed dark>
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-row>
      <v-col cols="12" class="text-center text-uppercase title">Administrar Usuarios</v-col>
      <v-col cols="12">
        <lista-usuarios :usuario="usuario" :usuarios="usuarios" v-on:click-remove="clickRemove"></lista-usuarios>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ListaUsuarios from "../../../components/usuarios/Lista";

export default {
  props: {
    usuario: Object,
    usuarios: Object
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    clickRemove(data) {
      var doc = {};
      doc = Object.assign({}, doc, data);
      this.$emit("delete-usuario", doc.id);
    }
  },
  components: {
    ListaUsuarios
  }
};
</script>

<style>
</style>