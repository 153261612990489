<template>
  <v-container>
    <v-row>
      <v-col>
        <formulario-archivo
          :datos="{id: idPDF(),ref:'/pdfs/'+idPDF()+'@catalobort.pdf'}"
          v-on:set-archivo="setArchivo"
        ></formulario-archivo>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormularioArchivo from "../../components/archivos/Formulario";

export default {
  props: {
    archivos: Object
  },
  data() {
    return {
      doc: null
    };
  },
  computed: {},
  watch: {
    archivos(val) {
      console.log("archivos")
      if (val && this.doc) {
        val[this.doc.id]
          ? this.$router.push({ name: "home.inicio" })
          : console.log("cargando");
      }
    }
  },
  methods: {
    setArchivo(doc) {
      this.doc = doc;
      this.$emit("set-archivo", doc);
      this.$emit("set-pdf", {
        id: doc.id,
        ref: doc.ref,
        fecha: new Date().toISOString()
      });
    },
    idPDF() {
      return this.fechaLocal() + "@" + this.horaLocal();
    },
    fechaLocal() {
      let fecha = new Date();
      return (
        fecha.getFullYear().toString() +
        "-" +
        (fecha.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        fecha
          .getDate()
          .toString()
          .padStart(2, "0")
      );
    },
    horaLocal() {
      let fecha = new Date();
      return (
        fecha
          .getHours()
          .toString()
          .padStart(2, "0") +
        ":" +
        fecha
          .getMinutes()
          .toString()
          .padStart(2, "0") +
        ":" +
        fecha
          .getSeconds()
          .toString()
          .padStart(2, "0")
      );
    }
  },
  components: {
    FormularioArchivo
  }
};
</script>

<style>
</style>