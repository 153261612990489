import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import HomeCargando from '../views/home/Cargando'
import HomeInicio from '../views/home/Inicio'
import HomeSubida from '../views/home/SubidaTemp'
import HomeAdmin from '../views/home/Admin'
import HomeAdminInicio from '../views/home/admin/Inicio'
import HomeAdminUsuarios from '../views/home/admin/Usuarios'
import HomeAdminPDFs from '../views/home/admin/PDFs'
import Registro from '../views/Registro'
import Login from '../views/Login'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    component: Home,
    children: [{
        path: '',
        name: 'home',
        redirect: {
          name: "home.inicio"
        }
      }, {
        path: 'cargando',
        name: 'home.cargando',
        component: HomeCargando
      }, {
        path: 'catalobort',
        name: 'home.inicio',
        component: HomeInicio
      },
      {
        path: 'perita',
        name: 'home.subir',
        component: HomeSubida
      },
      {
        path: 'admin',
        component: HomeAdmin,
        children: [{
            path: '',
            name: 'home.admin',
            redirect: {
              name: "home.admin.inicio"
            }
          }, {
            path: 'usuarios',
            name: 'home.admin.usuarios',
            component: HomeAdminUsuarios
          }, {
            path: 'inicio',
            name: 'home.admin.inicio',
            component: HomeAdminInicio
          },
          {
            path: 'pdfs',
            name: 'home.admin.pdfs',
            component: HomeAdminPDFs
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/registro',
    name: 'registro',
    component: Registro
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router